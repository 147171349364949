import React from "react";
import DesktopHeader from "./desktopHeader";
import MobileHeader from "./mobileHeader";

function Header() {
  return (
    <>
      <MobileHeader />
      <DesktopHeader />
    </>
  );
}

export default Header;
