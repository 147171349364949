import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";

import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaRegAddressBook } from "react-icons/fa";
import TextField from "@mui/material/TextField";

function Contact() {
  useEffect(() => {
    document.title = "Wohn Projekt | Kontakt";
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(
      "https://nodemailer.wohn-projekt.ch/send-email",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, message }),
        mode: 'cors',
        credentials: 'include'
      }
    );

    // Add the CORS headers to the response
    response.headers.set('Access-Control-Allow-Origin', 'https://wohn-projekt.ch');
    response.headers.set('Access-Control-Allow-Credentials', 'true');

    const data = await response.json();

    if (data.status === "success") {
      setStatus("Wir danken Ihnen für Ihre Nachricht!");
    } else if (data.status === "fail") {
      setStatus(
        "Bei der Übermittlung Ihrer Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      );
    }
  };

  return (
    <section className=" body-wrapper contact-us">
      <div className="contact-us-content">
        <div className="map">
          <div className="map-card">
            <div className="map-card-title">
              <h2>Finden Sie uns auf Google Map</h2>
              <div className="map-card-body">
                <Iframe
                  src="https://maps.google.com/maps?q=9242 Oberuzwil, Buchen 3120&t=&z=17&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  className="Iframe-map"
                  allowfullscreen
                ></Iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div div className="card-inputs">
            <div className="card-title">
              <h2>Kontaktieren Sie uns</h2>
              <p>
                Kontaktieren Sie uns jederzeit. Wir melden uns zeitnah bei
                Ihnen.
              </p>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mui-input">
                  <TextField
                    sx={{
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#c2ba60ff",
                      },
                    }}
                    id="standard-basic"
                    label="Name"
                    variant="standard"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mui-input">
                  <TextField
                    sx={{
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#c2ba60ff",
                      },
                    }}
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mui-input">
                  <TextField
                    sx={{
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#c2ba60ff",
                      },
                    }}
                    id="standard-multiline-flexible"
                    multiline
                    label="Nachricht"
                    variant="standard"
                    maxRows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>

                <div>
                  <button className="submit-button" type="submit">
                    Senden
                  </button>
                </div>
              </form>
              <p>{status}</p>
            </div>
          </div>
          <div className="card-right">
            <div className="card-info">
              <h2>Kontaktinfo</h2>
              <div className="contact-info">
                <FaPhoneAlt />
                <p>+79 552 00 21</p>
              </div>
              <div className="contact-info">
                <FaEnvelope />
                <p>info@wohn-projekt.com</p>
              </div>
              <div className="contact-info">
                <FaRegAddressBook />
                <p>Buchen 3120, 9242 Oberuzwil</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
