import "./App.scss";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import routes from "./routes";
import Footer from "./components/footer";
import Header from "./components/header/header.js";
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        {routes.map((route, i) => {
          const { path } = route;
          const Comp = route.component;
          return (
            <Route exact path={path} key={path + "-" + i} element={<Comp />} />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
