import Home from "./views/home";
import Contact from "./views/contact";
import References from "./views/references";
import Services from "./views/services";

const routes = [
  {
    path: "/services",
    component: Services,
  },
  {
    path: "/references",
    component: References,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/",
    component: Home,
  },
];

export default routes;
