import React, { useEffect } from "react";

function References() {
  useEffect(() => {
    document.title = "Wohn Projekt | Referenz";
  }, []);
  return <div className="body-wrapper"></div>;
}

export default References;
