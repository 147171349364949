import React from "react";
import { FaAngleRight, FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion, useCycle } from "framer-motion";

import headerLogo from "../../assets/Images/logos/wohnprojekt-logo.png";

const links = [
  { name: "Home", to: "/", id: 1 },
  { name: "Dienstleistungen", to: "/services", id: 2 },
  { name: "Kontakt", to: "/contact", id: 3 },
];
const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};
const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
};

function DesktopHeader() {
  const [open, cycleOpen] = useCycle(false, true);
  return (
    <>
      <nav className="header-main-nav">
        <FaBars className="header-icon" onClick={cycleOpen} />
        <AnimatePresence>
          {open && (
            <motion.aside
              initial={{ width: 0 }}
              animate={{
                width: "60%",
              }}
              exit={{
                width: 0,
                transition: { delay: 0.8, duration: 0.2 },
              }}
            >
              <motion.div
                className="container"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <motion.div
                  className="mobile-menu-collapse-btn"
                  variants={itemVariants}
                >
                  <FaAngleRight
                    className="mobile-menu-collapse-icon"
                    size={42}
                    onClick={cycleOpen}
                  />
                </motion.div>
                <motion.div className="mobile-menu-items">
                  {links.map(({ name, to, id }) => (
                    <motion.a
                      key={id}
                      href={to}
                      whileHover={{ scale: 1.1 }}
                      variants={itemVariants}
                    >
                      {name}
                    </motion.a>
                  ))}
                </motion.div>
              </motion.div>
            </motion.aside>
          )}
        </AnimatePresence>

        <NavLink className="header-logo" to="/">
          <img className="logo" src={headerLogo} alt="wohn-projekt-logo" />
        </NavLink>
        <div className="header-items">
          <NavLink className={"header-nav-item"} to="/">
            Home
          </NavLink>
          <NavLink className={"header-nav-item"} to="/services">
            Dienstleistungen
          </NavLink>
          <NavLink className={"header-nav-item"} to="/contact">
            Kontakt
          </NavLink>
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
        </div>
      </nav>
    </>
  );
}

export default DesktopHeader;
