import React, { useEffect } from "react";
import { FadeIn } from "react-slide-fade-in";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink } from "react-router-dom";
import ContactHomeImage from "../assets/Images/homepage/contact.jpg";
import "swiper/scss";
import "swiper/scss/effect-creative";
import slider1 from "../assets/Images/homepage/homePage.jpg";
import slider2 from "../assets/Images/homepage/homePage2.jpg";
import slider3 from "../assets/Images/homepage/homePage3.jpg";
import slider4 from "../assets/Images/homepage/homePage4.jpg";
import project from "../assets/Images/homepage/architectural-icon.svg";
import plaster from "../assets/Images/homepage/services-painting-icon.svg";
import floor from "../assets/Images/homepage/floor-cleaning-icon.svg";

import { EffectCreative, Autoplay } from "swiper";

function Home() {
  useEffect(() => {
    document.title = "Wohn Projekt | Home";
  }, []);

  const renderCards = () => {
    const cardData = [
      {
        details: ["Allgemeine Gipserarbeiten", "Spritzarbeiten", "Tapezieren"],
        svg: plaster,
        title: "Allgemeine Maler-und Gipserarbeiten",
      },
      {
        details: ["Teppich", "Epoxy", "Linoleum", "Parkett", "Vinyl"],
        svg: floor,
        title: "Bodenbeläge",
      },
      {
        details: ["Baukalkulation", "Baumanagement", "Projektleitung"],
        svg: project,
        title: "Planung und Projektierung",
      },
    ];
    return (
      <div className="services-section-container">
        {cardData.map(({ title, svg, details }, i) => {
          return (
            <FadeIn
              from="left"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={800}
            >
              <div className="card" key={"card-" + i}>
                <div className="face face1">
                  <div className="content">
                    <img src={svg} />
                    <span>{title}</span>
                  </div>
                </div>
                <div className="face face2">
                  <div className="content">
                    {details.map((detail, i) => {
                      return <p key={"content-" + i}>{detail}</p>;
                    })}
                  </div>
                </div>
              </div>
            </FadeIn>
          );
        })}
      </div>
    );
  };

  return (
    <div className="body-wrapper">
      <div className="swiper-container">
        <Swiper
          grabCursor={true}
          loop={true}
          autoplay={{ delay: 1800, disableOnInteraction: false }}
          effect={"creative"}
          speed={1150}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-125%", 0, -800],
              rotate: [0, 0, -90],
            },
            next: {
              shadow: true,
              translate: ["125%", 0, -800],
              rotate: [0, 0, 90],
            },
          }}
          modules={[EffectCreative, Autoplay]}
          className="mySwiper5"
        >
          <SwiperSlide
            style={{ backgroundImage: `url(${slider1})` }}
          ></SwiperSlide>
          <SwiperSlide
            style={{ backgroundImage: `url(${slider2})` }}
          ></SwiperSlide>
          <SwiperSlide
            style={{ backgroundImage: `url(${slider3})` }}
          ></SwiperSlide>
          <SwiperSlide
            style={{ backgroundImage: `url(${slider4})` }}
          ></SwiperSlide>
        </Swiper>
      </div>
      <FadeIn
        from="bottom"
        positionOffset={200}
        triggerOffset={100}
        delayInMilliseconds={0}
      >
        <div className="slogan-container">
          <h1>
            Wir bieten Ihnen keine Angebote
            <br /> Wir schaffen Ihr Zuhause
          </h1>
          <h3 className="slogan">Wir bauen, Sie wohnen</h3>
        </div>
      </FadeIn>
      <div className="services-section">
        <div className="services-section-title">
          <FadeIn
            from="bottom"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={0}
          >
            <h1>Was wir anbieten</h1>
          </FadeIn>
        </div>
        {renderCards()}
      </div>

      <FadeIn
        from="bottom"
        positionOffset={200}
        triggerOffset={200}
        delayInMilliseconds={0}
      >
        <div className="references-section">
          <h1>Referenz</h1>
          <div className="references-section-details">
            <div className="references-section-details-title">
              <div>
                <p className="title">Sanneubau AG</p>
                <p className="work">Fassadenarbeiten & Renovationen</p>
              </div>
              <div>
                <p className="title">Andreas Ludwig Kalker</p>
                <p className="work">Malerarbeiten & Gipserarbeiten</p>
              </div>
              <div>
                <p className="title">Confon AG</p>
                <p className="work">Bodenbeläge</p>
              </div>
              <div>
                <p className="title">Image Homes AG</p>
                <p className="work">
                  Bauplanung, Bauleitung, Renovierung & Kalkulation
                </p>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
      <div className="contact-homepage">
        <div className="contact-homepage-data">
          <h3>
            Mit unseren Projektierungsmöglichkeiten werden Ihre Träume wahr.
            Warten Sie nicht länger und lassen Sie Ihrer Fantasie freien Lauf.
          </h3>
          <button className="contact-homepage-data-button">
            <NavLink className="links" to="/contact">
              Kontaktieren Sie uns
            </NavLink>
          </button>
        </div>
        <div className="contact-homepage-image">
          <img
            src={ContactHomeImage}
            alt="whon-projekt-homepage-contact"
            className="homepage-contact-image-img"
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
