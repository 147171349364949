import React, { useEffect, useState, useCallback } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ImageViewer from "react-simple-image-viewer";
import { importAllImages } from "../helper/imageImporter";
// Import Swiper styles
import "swiper/scss";
import "swiper/css/effect-cube";
import "swiper/scss/pagination";

// import required modules
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from "swiper";

import "../assets/scss/services.scss";
// import { ImgsViewer } from 'react-images-viewer';
import hero from "./../assets/Images/services/servicesPage/cranes-7185222_1280.png";

function Services() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [sliderTitle, setSliderTitle] = useState("Bodenbeläge");
  const [sliderTitleDesc, setSliderTitleDesc] = useState(
    "Egal, ob Sie den roten Teppich ausrollen oder in jeden Raum gleiten wollen, wir haben alles. Überlassen Sie einfach Ihren Füßen das Gehen."
  );
  const [sliderSubTitle, setSliderSubTitle] = useState("Teppich");
  const [phrase, setPhrase] = useState("Das kuschelig-warme Zuhause");
  const [description, setDescription] = useState(
    "Fühlen Sie das kuschelige Gefühl jeden Tag an Ihren Füssen und geniessen Sie die Wärme-speichernden Fähigkeiten der Teppichböden, welche bereits seit Generationen geschätzt und geliebt werden."
  );

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    document.title = "Whon Projekt | Dienstleistungen";
    prepareGaleryImages();
  }, []);

  const servicesData = [
    {
      name: "Bodenbeläge",
      images: {
        carpet: importAllImages(
          require.context(
            "../assets/Images/services/floorCovering/carpet",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        epoxy: importAllImages(
          require.context(
            "../assets/Images/services/floorCovering/epoxy",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        lino: importAllImages(
          require.context(
            "../assets/Images/services/floorCovering/lino",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        parquet: importAllImages(
          require.context(
            "../assets/Images/services/floorCovering/parquet",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        vinyl: importAllImages(
          require.context(
            "../assets/Images/services/floorCovering/vinyl",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        slider: importAllImages(
          require.context(
            "../assets/Images/services/floorCovering/floorCoveringSlider",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
      },
    },
    {
      name: "Maler- und Gipserarbeiten",
      images: {
        plastering: importAllImages(
          require.context(
            "../assets/Images/services/paintingAndPlastering/plastering",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        sprayPainting: importAllImages(
          require.context(
            "../assets/Images/services/paintingAndPlastering/SprayPainting",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        wallpapering: importAllImages(
          require.context(
            "../assets/Images/services/paintingAndPlastering/Wallpapering",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
        slider: importAllImages(
          require.context(
            "../assets/Images/services/paintingAndPlastering/paintingAndPlasteringSlider",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
      },
    },
    {
      name: "Planung und Projektierung",
      images: {
        slider: importAllImages(
          require.context(
            "../assets/Images/services/projectManagement/projectManagementSlider",
            true,
            /\.(png|jpe?g|svg)$/
          ),
          "./images"
        ),
      },
    },
  ];

  const imageschange = (images) => {
    const img = [];
    Object.keys(images).map((key, index) => img.push(images[key]));
    setImages(img);
  };

  const [images, setImages] = useState();

  const [activeSlideIndexes, setActiveSlideIndexes] = useState([0, 0]);

  useEffect(() => {
    prepareGaleryImages();
  }, [activeSlideIndexes]);

  const handleSlideChange = (swiper, direction) => {
    if (direction === "horizontal") {
      setActiveSlideIndexes([swiper.realIndex, 0]);
    } else if (direction === "vertical") {
      setActiveSlideIndexes([activeSlideIndexes[0], swiper.realIndex]);
    }
  };

  const prepareGaleryImages = () => {
    switch (activeSlideIndexes[0]) {
      case 0:
        setSliderTitle(servicesData[activeSlideIndexes[0]].name);
        setSliderTitleDesc(
          "Egal, ob Sie den roten Teppich ausrollen oder in jeden Raum gleiten wollen, wir haben alles. Überlassen Sie einfach Ihren Füßen das Gehen."
        );
        switch (activeSlideIndexes[1]) {
          case 0:
            setSliderSubTitle("Teppich");
            setPhrase("Das kuschelig-warme Zuhause");
            setDescription(
              "Fühlen Sie das kuschelige Gefühl jeden Tag an Ihren Füssen und geniessen Sie die Wärme-speichernden Fähigkeiten der Teppichböden, welche bereits seit Generationen geschätzt und geliebt werden."
            );
            imageschange(servicesData[activeSlideIndexes[0]].images.carpet);
            break;
          case 1:
            setSliderSubTitle("Epoxy");
            setPhrase("Der strapazierfähige Boden für Zuhause");
            setDescription(
              "Geben Sie Ihrem Zuhause die notwendige Robustheit und Stärke. Unsere nahezu verschleissfreien und extrem belastbaren Epoxy-Böden bestehen aus qualitativ hochwertigen sowie umweltschonenden Materialien."
            );
            imageschange(servicesData[activeSlideIndexes[0]].images.epoxy);
            break;
          case 2:
            setSliderSubTitle("Linoleum");
            setPhrase("Für ein hygienisch-reines Zuhause");
            setDescription(
              "Geniessen Sie Ihr Leben in vollen Zügen mit einem hygienisch-reinen Zuhause. Unsere Linoleumböden sind pflegeleicht und weisen eine antibakterielle Wirkung auf, welche Allergikern zugutekommt."
            );
            imageschange(servicesData[activeSlideIndexes[0]].images.lino);
            break;
          case 3:
            setSliderSubTitle("Parkett");
            setPhrase("Ein Stück Natur für ihr Zuhause");
            setDescription(
              "Steigern Sie Ihr Wohlbefinden, indem Sie die Balance und Kraft der Natur auf Sie einwirken lassen. Unsere vielfältige Kollektion bringt die Natur in Ihr Zuhause."
            );
            imageschange(servicesData[activeSlideIndexes[0]].images.parquet);
            break;
          case 4:
            setSliderSubTitle("Vinyl");
            setPhrase("Ein Belag für alle Lebenslagen");
            setDescription(
              "Lassen Sie Ihrer Inspiration freien Lauf und leben Sie Ihre Träume aus. Unsere grosse Auswahl an Dekoren und Farben lassen Künstlerherzen höherschlagen."
            );
            imageschange(servicesData[activeSlideIndexes[0]].images.vinyl);
            break;
          default:
            setImages([]);
            break;
        }
        break;
      case 1:
        setSliderTitle(servicesData[activeSlideIndexes[0]].name);
        setSliderTitleDesc(
          "Jedes Haus braucht ein gemütliches Aussehen und das startet mit dem Glätten der Ecken und Kanten. Wir verwandeln ein raues Aussehen in eine glatte Leinwand oder in ein herzerwärmenden Look, von Anfang bis Ende."
        );

        switch (activeSlideIndexes[1]) {
          case 0:
            setSliderSubTitle("Allgemeine Gipserarbeiten");
            setPhrase("Glatte und robuste Leinwände für die Kunst");
            setDescription(
              "Bevor eine Wand von einem Maler zum Strahlen gebracht wird, sollte sie zunächst einmal sorgfältig vorbereitet werden, um die Farbe in voller Pracht geniessen zu können. Ein glatter und robuster Untergrund ist somit ein Muss. Was für ein Glück, dass dies zu einer unserer Spezialitäten gehört."
            );
            imageschange(servicesData[activeSlideIndexes[0]].images.plastering);
            break;
          case 1:
            setSliderSubTitle("Spritzarbeiten");
            setPhrase("Bunte und strahlende Farben für Ihr Leben");
            setDescription(
              "Unsere hochwertigen Farben besitzen einen hohen Anteil an Pigmenten und haben somit eine hohe Deckkraft. Die verwendeten Naturfarben sind bekannt für ihre natürlichen Inhaltstoffen und sollen Gesundheit sowie Umwelt besonders schonen."
            );
            imageschange(
              servicesData[activeSlideIndexes[0]].images.sprayPainting
            );
            break;
          case 2:
            setSliderSubTitle("Tapezieren");
            setPhrase("Klassische und moderne Wandspielerein");
            setDescription(
              "Wir interpretieren die klassischen Wandtapeten vollkommen neu, um eindrucksvolle und einzigartige Kunstwerke zu erschaffen. Die Individualisierungs- und Ausdrucksmöglichkeiten scheinen grenzenlos zu sein."
            );
            imageschange(
              servicesData[activeSlideIndexes[0]].images.wallpapering
            );
            break;
          default:
            setImages([]);
        }
        break;
      case 2:
        setSliderTitle(servicesData[activeSlideIndexes[0]].name);
        setSliderTitleDesc(
          "Wir verstehen uns auf die Planung Ihrer Projekte genauso gut wie auf das Bauen."
        );

        setImages([]);
        switch (activeSlideIndexes[1]) {
          case 0:
            setSliderSubTitle("Projektleitung");
            setPhrase("Übergeben Sie uns das Steuer");
            setDescription(
              "Lehnen Sie sich zurück, während wir Ihre Träume realisieren. Wir übernehmen die Verantwortung und Leitung der gesamten Projekte, um einen reibungslosen Ablauf sowie einen klaren Informationsaustausch zu gewährleisten."
            );
            break;
          case 1:
            setSliderSubTitle("Baumanagement");
            setPhrase("Lassen Sie uns gemeinsam Träume wahr werden");
            setDescription(
              "Während Sie Ihre kostbare Zeit geniessen, verwirklichen wir für Sie Ihre grossartigen Träume. Die Zusammenarbeit mit vielen erfolgreichen und langjährigen Partnern ermöglicht uns nahezu unendliche Möglichkeiten bei der Verwirklichung Ihrer Wünsche. Individualisieren Sie Ihr Leben und lassen Sie uns gemeinsam Ihr Zuhause kreieren. Je ausgefallener, desto motivierender ist unser Team, Sie uns ihr Leben verändern."
            );
            break;
          case 2:
            setSliderSubTitle("Baukalkulation");
            setPhrase("Übersichtlich und transparent");
            setDescription(
              "Damit Sie sorgenfrei Ihre Projekte realisieren können, garantiert Ihnen unser Alles-aus-einer-Hand-Konzept einzigartige Angebote und eine saubere Kostenaufstellung. So lassen sich die Finanzen unkomplizierter bearbeiten."
            );
            break;
          default:
            setImages([]);
        }
        break;
      default:
        setImages([]);
    }
  };

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence

  return (
    <div className="body-wrapper">
      <div className="services-hero-img">
        <img src={hero} alt="hero-img" />
      </div>
      <div className="services-hero-title">
        <h1>Unser Angebot</h1>
        <p>
          Ganz gleich, ob Sie von Grund auf bauen oder nur einen Teil renovieren
          möchten, unsere Dienstleistungen eignen sich für alles.
        </p>
      </div>
      {window.innerWidth > 900 ? (
        <div className="desktop-services">
          <h1 className="service-title">{sliderTitle}</h1>
          <h3 className="service-subtitle">{sliderTitleDesc}</h3>
          <div className="desktop-swiper">
            <Swiper
              onSlideChange={(swiper) =>
                handleSlideChange(swiper, "horizontal")
              }
              direction={"horizontal"}
              spaceBetween={50}
              pagination={{
                clickable: true,
              }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              loop={true}
              className="mySwiper swiper-h"
            >
              <SwiperSlide>
                <Swiper
                  onSlideChange={(swiper) =>
                    handleSlideChange(swiper, "vertical")
                  }
                  direction={"vertical"}
                  className="mySwiper2 swiper-v"
                  spaceBetween={500}
                  pagination={true}
                  modules={[Pagination, Navigation, Scrollbar, A11y]}
                  loop={true}
                >
                  {Object.keys(servicesData[0].images.slider).map(
                    (key, index) => (
                      <SwiperSlide key={index}>
                        <img
                          key={index}
                          src={
                            servicesData[0].images.slider[
                              activeSlideIndexes[1] + 1 + ".jpg"
                            ]
                          }
                          alt=""
                        />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </SwiperSlide>
              <SwiperSlide>
                <Swiper
                  onSlideChange={(swiper) =>
                    handleSlideChange(swiper, "vertical")
                  }
                  direction={"vertical"}
                  className="mySwiper2 swiper-v"
                  spaceBetween={500}
                  pagination={true}
                  modules={[Pagination, Navigation, Scrollbar, A11y]}
                  loop={true}
                >
                  {Object.keys(servicesData[1].images.slider).map(
                    (key, index) => (
                      <SwiperSlide key={index}>
                        <img
                          key={index}
                          src={
                            servicesData[1].images.slider[
                              activeSlideIndexes[1] + 1 + ".jpg"
                            ]
                          }
                          alt=""
                        />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </SwiperSlide>
              <SwiperSlide>
                <Swiper
                  onSlideChange={(swiper) =>
                    handleSlideChange(swiper, "vertical")
                  }
                  direction={"vertical"}
                  className="mySwiper2 swiper-v"
                  spaceBetween={500}
                  pagination={true}
                  modules={[Pagination, Navigation, Scrollbar, A11y]}
                  loop={true}
                >
                  {Object.keys(servicesData[2].images.slider).map(
                    (key, index) => (
                      <SwiperSlide key={index}>
                        <img
                          key={index}
                          src={
                            servicesData[2].images.slider[
                              activeSlideIndexes[1] + 1 + ".jpg"
                            ]
                          }
                          alt=""
                        />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </SwiperSlide>
            </Swiper>
          </div>
          <h2 className="description">{sliderSubTitle}</h2>
          <h4 className="phrase">{phrase}</h4>
          <p className="description-text">{description}</p>
          <div className="desktop-gallery">
            {images?.map((src, index) => (
              <img
                src={src}
                onClick={() => openImageViewer(index)}
                width="300"
                key={index}
                style={{ margin: "2px" }}
                alt=""
              />
            ))}

            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="mobile-services">
          <h1 className="service-title">{sliderTitle}</h1>
          <h3 className="service-subtitle">{sliderTitleDesc}</h3>
          <div className="mobile-swiper">
            <Swiper
              onSlideChange={(swiper) =>
                handleSlideChange(swiper, "horizontal")
              }
              grabCursor={true}
              direction={"vertical"}
              spaceBetween={10}
              loop={true}
              cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }}
              pagination={true}
              modules={[EffectCube, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <Swiper
                  onSlideChange={(swiper) =>
                    handleSlideChange(swiper, "vertical")
                  }
                  effect={"cube"}
                  grabCursor={true}
                  pagination={true}
                  loop={true}
                  cubeEffect={{
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 0,
                    shadowScale: 0,
                  }}
                  modules={[EffectCube, Pagination]}
                >
                  {Object.keys(servicesData[0].images.slider).map(
                    (key, index) => (
                      <SwiperSlide key={index}>
                        <img
                          key={index}
                          src={
                            servicesData[0].images.slider[
                              activeSlideIndexes[1] + 1 + ".jpg"
                            ]
                          }
                          alt=""
                        />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </SwiperSlide>
              <SwiperSlide>
                <Swiper
                  onSlideChange={(swiper) =>
                    handleSlideChange(swiper, "vertical")
                  }
                  effect={"cube"}
                  grabCursor={true}
                  pagination={true}
                  loop={true}
                  cubeEffect={{
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 0,
                    shadowScale: 0,
                  }}
                  modules={[EffectCube, Pagination]}
                >
                  {Object.keys(servicesData[1].images.slider).map(
                    (key, index) => (
                      <SwiperSlide key={index}>
                        <img
                          key={index}
                          src={
                            servicesData[1].images.slider[
                              activeSlideIndexes[1] + 1 + ".jpg"
                            ]
                          }
                          alt=""
                        />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </SwiperSlide>
              <SwiperSlide>
                <Swiper
                  onSlideChange={(swiper) =>
                    handleSlideChange(swiper, "vertical")
                  }
                  effect={"cube"}
                  grabCursor={true}
                  pagination={true}
                  loop={true}
                  cubeEffect={{
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 0,
                    shadowScale: 0,
                  }}
                  modules={[EffectCube, Pagination]}
                >
                  {Object.keys(servicesData[2].images.slider).map(
                    (key, index) => (
                      <SwiperSlide key={index}>
                        <img
                          key={index}
                          src={
                            servicesData[2].images.slider[
                              activeSlideIndexes[1] + 1 + ".jpg"
                            ]
                          }
                          alt=""
                        />
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="mobile-services-gallery">
            <h2 className="description">{sliderSubTitle}</h2>
            <h4 className="phrase">{phrase}</h4>
            <p className="description">{description}</p>
            {images?.map((src, index) => (
              <img
                src={src}
                onClick={() => openImageViewer(index)}
                width="300"
                key={index}
                style={{ margin: "2px" }}
                alt=""
              />
            ))}

            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Services;
