import React from "react";

import footerlogo from "../assets/Images/logos/wohnprojekt-logo.png";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer-distributed">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-left">
            <div className="logo">
              <img src={footerlogo} alt="logo" />
            </div>
          </div>
          <div className="footer-center">
            <div className="footer-links">
              <div>
                <NavLink className="links" to="/">
                  Home
                </NavLink>
                <NavLink className="links" to="/services">
                  Dienstleistungen
                </NavLink>
                <NavLink className="links" to="/contact">
                  Kontakt
                </NavLink>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <h4 className="footer-company-name">© 2023 WOHNPROJEKT</h4>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        © 2023 | All Rights Reserved by Metamorph System Development.
      </div>
    </footer>
  );
}

export default Footer;
